<template>
    <div class="class-patrol-tabs">
        <div class="tab-list" id="classPatrolTabList">
            <div v-for="(item, index) of tabList"
                :key="index"
                :class="['tab-item', {current: item._id == selectTabId}]"
                @click="handlerTabChange(item)"
            >
                {{ item.title }}
                <span v-if="tabList.length > 1" class="del" @click.stop="delCard(item, index)">
                    <img :src="delImg" alt="删除">
                </span>
            </div>
        </div>
        <div class="add-tab" v-if="tourDetailsClone.type !== 'CourseSchedule' && tourDetailsClone.type !== 'edit' && tourDetailsClone.type !== 'detail'">
            <img
                v-if="!tourDetailsClone.form"
                :src="addTabs"
                alt="添加卡片"
                @click="addTabCards"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: "ClassPatrolTabs",
    props: {
        tabList: Array,
        selectTabId: Number,
        tourDetailsClone: Object
    },
    computed: {
        addTabs () {
            return require('@/assets/images/TeachingResearchCourseScheduleTemp/add-tabs.png')
        },
        delImg () {
            return require('@/assets/images/del-card.png')
        }
    },
    methods: {
        addTabCards () {
            this.$eventDispatch('addTabCards')
        },
        handlerTabChange (data) {
            this.$eventDispatch('handlerTabChange', data)
        },
        delCard (item, index) {
            let _this = this;
            this.$confirm('是否删除当前记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$eventDispatch('delCard', {item: item, index: index})
            }).catch(() => {});


        }
    }
}
</script>

<style lang="scss" scoped>
    .class-patrol-tabs {
        height: 100%;
        display: flex;
        align-items: center;
        .tab-list {
            height: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 500px;
            text-align: center;
            white-space: nowrap;
            overflow-x: auto;
            z-index: 1;
            .tab-item {
                position: relative;
                margin: 0 6px;
                box-sizing: border-box;
                padding: 10px 20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808487;
                white-space: nowrap;
                display: inline-block;
                background: #F0F1F2;
                border-radius: 4px;
                cursor: pointer;
                .del {
                    position: absolute;
                    right: -5px;
                    top: -5px;
                }
            }
            .tab-item.current {
                color: #3C7FFF;
                background: #DCEBFA;
            }
        }
        .add-tab {
            width: 48px;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
        }
    }
</style>
