<template lang="html">
    <div class="editor" style="width: 100%;">
        <div ref="toolbar" class="toolbar"/>
        <div ref="editor" class="text" :style="{'height': `${height}`}"/>
        <el-dialog modal-append-to-body append-to-body title="剪切图片" class="cro-dialog" :visible.sync="cropper_visible" width="525px"
                   :modal="false" :close-on-click-modal="false">
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :info="true"
                        :fixed-box="option.fixedBox"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :can-scale="option.canScale"
                        :auto-crop="option.autoCrop"
                        :info-true="option.infoTrue"
                    />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="text" icon="el-icon-refresh-left" @click="rotateLeft">左旋转</el-button>
                <el-button type="text" icon="el-icon-refresh-right" @click="rotateRight">右旋转</el-button>
                <el-button @click="cropper_visible = false">取 消</el-button>
                <el-button type="primary" @click="finish">上传图片</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import E from 'wangeditor'
    import { VueCropper } from 'vue-cropper'
    import { uploadAction } from "@/libs/settings";
    import { CommonModel } from '@/models/Common'
    import { getToken } from "@/libs/auth";

    export default {
        components: { VueCropper },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            height: {
                type: String,
                default: '400px'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                editor: null,
                info_: null,
                cropper_visible: false,
                option: {
                    img: '', // 裁剪图片的地址
                    file: '',
                    info: true, // 裁剪框的大小信息
                    fixedBox: false,
                    autoCropWidth: 483,
                    autoCropHeight: 177,
                    canScale: true, // 图片是否允许滚轮缩放
                    autoCrop: true, // 是否默认生成截图框
                    infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                },
                insertImgFn: ''
            }
        },
        watch: {
            value: function (value) {
                if (value !== this.editor.txt.html()) {
                    this.editor.txt.html(this.value)
                }
            }
        },
        mounted() {
            this.setEditor()
            this.editor.txt.html(this.value)
        },
        methods: {
            async setEditor() {
                let _this = this;
                this.editor = new E(this.$refs.toolbar, this.$refs.editor)
                this.editor.config.menus = [
                    'head', // 标题
                    'bold', // 粗体
                    'fontSize', // 字号
                    'fontName', // 字体
                    'italic', // 斜体
                    'underline', // 下划线
                    'strikeThrough', // 删除线
                    'foreColor', // 文字颜色
                    'backColor', // 背景颜色
                    'link', // 插入链接
                    'list', // 列表
                    'justify', // 对齐方式
                    'quote', // 引用
                    'emoticon', // 表情
                    'image', // 插入图片
                    'video', // 视频
                    'table', // 表格
                    'code', // 插入代码
                    'undo', // 撤销
                    'redo', // 重复
                    'fullscreen' // 全屏
                ]
                this.editor.config.placeholder = this.placeholder
                this.editor.config.showLinkImg = false
                this.editor.config.height = 600
                this.editor.config.zIndex = 99
                // 粘贴处理
                // this.editor.config.pasteTextHandle = (pasteStr) => {
                //     // 需要判断是否是纯文本，不是纯文本执行函数，是纯文本返回
                //     // 验证图片中是否包含img标签，具有得到true否则得到false
                //     var containsImage = pasteStr.search(/<img /i) >= 0;
                //     // 存在图片就执行
                //     if (containsImage) {
                //         this.disposePasteImg(pasteStr).then((res) => {
                //             // 将内容追加上
                //             this.editor.txt.append(res);
                //         });
                //     }
                //
                //     return containsImage ? "" : pasteStr;
                // };
                // 配置上传图片
                this.editor.config.uploadImgServer = uploadAction
                this.editor.config.uploadImgMaxSize = 5 * 1024 * 1024
                this.editor.config.withCredentials = true
                this.editor.config.uploadFileName = 'file'
                this.editor.config.uploadImgHeaders = {
                    'Authorization': getToken()
                }
                this.editor.config.uploadImgHooks = {
                    customInsert: (insertImgFn, result) => {
                        if (result.code === '200') {
                            insertImgFn(result.data.url)
                        }
                    }
                }
                // 配置上传视频
                this.editor.config.uploadVideoServer = uploadAction
                this.editor.config.uploadVideoMaxSize = undefined
                this.editor.config.withCredentials = true
                this.editor.config.uploadVideoName = 'file'
                this.editor.config.uploadVideoHeaders = {
                    'Authorization': getToken()
                }
                this.editor.config.uploadVideoHooks = {
                    customInsert: (insertVideoFn, result) => {
                        if (result.code === '200') {
                            insertVideoFn(result.data.url)
                        }
                    }
                }

                
                // this.editor.config.customUploadImg = (resultFiles, insertImgFn, data) => {
                //   if (data) {
                //     insertImgFn(data.data.data.url)
                //     this.cropper_visible = false
                //   } else {
                //     this.insertImgFn = insertImgFn
                //     const isLt2M = resultFiles[0].size / 1024 / 1024 < 5;
                //     const isLt50 = resultFiles[0].name.length < 50
                //     if (!isLt50) {
                //       this.$message.error('上传图片名称不能超过50个字符!');
                //     }
                //     if (!isLt2M) {
                //       this.$message.error('上传图片大小不能超过5MB!');
                //     }
                //     if (isLt2M && isLt50) {
                //       this.cropper_visible = true
                //       // 上传预览图片  是个临时的缓存地址
                //       this.option.img = URL.createObjectURL(resultFiles[0])
                //       this.option.file = resultFiles[0];
                //     }
                //   }
                // }
                this.editor.config.onchange = (html) => {
                    this.info_ = html // 绑定当前逐渐地值
                    this.$emit('change', this.info_) // 将内容同步到父组件中
                }
                // 创建富文本编辑器
                this.editor.create()

                if (_this.disabled) {
                    this.editor.disable()
                }
               
            },
            // 处理粘贴的图片，传递的参数是粘贴来的数据
            disposePasteImg(pasteStr) {
                let _this = this;
                return new Promise(function (resolve) {
                    // 用于计数图片数量
                    let imgNum = 0;
                    //匹配图片
                    var imgReg = /<img.*?(?:>|\/>)/gi;
                    //匹配src属性
                    // eslint-disable-next-line no-useless-escape
                    var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;

                    // 提取图片连接
                    pasteStr.replace(imgReg, function (txt) {
                        return txt.replace(srcReg, function (src) {
                            var img_src = src.match(srcReg)[1];
                            // console.log(img_src);
                            //正则把?x-oss-process后面的都去掉
                            img_src = img_src.replace(/\?.*/i, "");
                            // 查找到一个图片地址就讲图片数量加1
                            imgNum++;

                            // 将图片转成图片文件对象并上传得到图片地址，传递的参数是图片地址
                            _this.imgUrlSwitchBlob(img_src).then((res) => {

                                /**
                                 * 得到图片地址进行替换并将替换之后的文本返回渲染
                                 */
                                // 图片地址进行替换
                                pasteStr = pasteStr.replace(img_src, res);
                                // 替换之后将图片数量减1
                                imgNum--;
                                // 只有图片数量变成0的时候才会进行返回
                                if (imgNum == 0) {
                                    // console.log("返回imgNum", imgNum,pasteStr)
                                    resolve(pasteStr);
                                }
                            });
                        });
                    });
                });
            },
            imgUrlSwitchBlob(param) {

                let _this = this;
                return new Promise(function (resolve) {
                    // 得到图片后缀
                    let suffix = param.substring(param.lastIndexOf(".") + 1); //获取后缀
                    // 设置图片名称及后缀
                    const key =
                        new Date().getTime() +
                        Math.random().toString().substr(2, 5) +
                        "." +
                        suffix;
                    // 创建图片对象
                    let image = new Image();
                    // 允许跨域
                    // image.crossOrigin = 'Anonymous'
                    image.src = param;
                    image.onload = () => {
                        let canvas = document.createElement("canvas");
                        canvas.width = image.width;
                        canvas.height = image.height;
                        let ctx = canvas.getContext("2d");
                        ctx.drawImage(image, 0, 0, image.width, image.height);
                        canvas.toBlob((blob) => {
                            // console.log("blob", blob)
                            // 得到的blob是一个图片对象，经图片对象进行上传得到图片地址.key是文件名称
                            /**
                             * @函数名称: 调用函数进行上传
                             * @返回值: 得到的图片路径
                             * @param {String} key 图片名称，包含后缀
                             * @param {Object} blob 文件对象
                             */
                            _this.upPasteImg(key, blob).then((res) => {
                                resolve(res);
                            });
                        });
                    };
                });
            },
            upPasteImg(key, file) {
                const formData = this.getUploadFormData(file, key, file.type)
                let commonModel = new CommonModel()
                return new Promise((resolve) => {
                    commonModel.uploadFile(formData).then((res) => {
                        if (res.data.code === '200') {
                            resolve(res.data.data.url)
                        }
                    })
                })
            },
            //向左旋转
            rotateLeft() {
                this.$refs.cropper.rotateLeft()
            },
            //向右旋转
            rotateRight() {
                this.$refs.cropper.rotateRight()
            },
            finish() {
                this.$refs.cropper.getCropBlob((data) => {
                    const formData = this.getUploadFormData(data, this.option.file.name, data.type)
                    let commonModel = new CommonModel()
                    commonModel.uploadFile(formData).then((res) => {
                        if (res.data.code === '200') {
                            this.editor.config.customUploadImg('', this.insertImgFn, res)
                        }
                    })
                })
            },
            getUploadFormData(data, key, type) {
                let newFile = new window.File([data], key, { type: type })
                let formData = new FormData()
                formData.append('file', newFile)
                return formData
            },
        }
    }
</script>

<style lang="scss" scoped>
    .editor {
        border: 1px solid #ccc;

        ::-webkit-scrollbar {
            width: 4px;
            height: 10px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #C0C4CC;
        }

        ::-webkit-scrollbar-track {
            border-radius: 0;
            background: #f2f6fe;
        }
    }

    /deep/ .w-e-toolbar {
        background-color: #f2f6fe;
    }

    .text {
        border-top: 1px solid #ccc;
        overflow-y: auto;
    }

    .cropper-content {
        display: flex;
        justify-content: center;

        .cropper {
            width: 500px;
            height: 300px;
        }

        .vue-cropper {
            background: rgb(155, 152, 152);
        }
    }
</style>
