<template>
    <div class="class-patrol-title">
        <!-- <div v-if="source != 'ClassHourStatistics'" class="back-wrapper">
            <img :src="backImg" alt="返回" @click="classPatrolBack"/>
            <span @click="classPatrolBack">返回</span>
        </div> -->
        <div class="tabs">
            <Tabs
                :tabList="tabList"
                :selectTabId="selectTabId"
                :tourDetailsClone="tourDetailsClone"
            />
        </div>
        <div class="btn-option">
            <el-button v-if="tourDetailsClone.type !== 'detail'" type="primary" @click="submitAll">全部提交</el-button>
        </div>
    </div>
</template>
<script>
import Tabs from './Tab.vue'
export default {
    name: "ClassPatrolTempTitle",
    components: {
        Tabs
    },
    props: {
        tabList: Array,
        selectTabId: Number,
        tourDetailsClone: Object,
        source: String
    },
    computed: {
        backImg () {
            return require('@/assets/images/TeachingResearchCourseScheduleTemp/back.png');
        }
    },
    methods: {
        classPatrolBack () {
            this.$eventDispatch('classPatrolBack')
        },
        submitAll () {
            this.$emit('submitAll')
        }
    }
}
</script>
<style lang="scss" scoped>
    .class-patrol-title {
        height: 60px;
        display: flex;
        align-items: center;
        .back-wrapper {
            width: 82px;
            cursor: pointer;
            display: flex;
            align-items: center;

            img {
                width: 10px;
                height: 7px;
            }
            span {
                margin-left: 8px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #363A40;
            }
        }
        .tabs {
            height: 100%;
            flex: 1;
        }
        .btn-option {
            width: 120px;
            display: flex;
            justify-content: flex-end;
        }
    }
</style>
