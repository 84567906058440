<template>
    <div class="richEditor-wrapper"> 
        <RichEditor  
            v-model="data.otherInformation"
            height="100px"         
            placeholder="请输入内容"
            :disabled="tourDetailsClone.type == 'detail'"
            @change="handlerChangeDeep"
        />
    </div>
</template>
<script>
    import RichEditor from './RichEditor.vue'
    export default {
        name: "Problem",
        components: {
            RichEditor
        },
        props: {
            data: Object,
            tourDetailsClone: Object
        },
        methods: {
            handlerChangeDeep () {
                this.$eventDispatch('handlerChangeDeep')
            }
        }
    }
</script>
<style lang="scss">
    .richEditor-wrapper {
        box-sizing: border-box;
        padding: 0 20px;
    }
</style>